import axios from 'axios';
import { url } from '../utils/url';

export const getPatients = async (token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.get(`${url}/api/patient/all`, config);
    const patients = response.data;
    for (const patient of patients) {
      const url = await getPresignedUrl(patient.avatar, token);
      response.data.avatar = url;
    }
    return patients;
  } catch (error) {
    return error.response.data;
  }
}

export const createPatient = async (data, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.post(`${url}/api/patient/create`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updatePatient = async (id, data, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.put(`${url}/api/patient/update/${id}`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const uploadFile = async (data, token) => {
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
  }
  try {
    const response = await axios.post(`${url}/api/patient/file`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getAllFiles = async (token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.get(`${url}/api/patient/contents`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getFileRecords = async (patientId, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.get(`${url}/api/patient/files/${patientId}`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getPresignedUrl = async (key, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };

    const response = await axios.post(`${url}/api/patient/patient-file`, {
      key: encodeURIComponent(key)
    }, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getKeysByPatient = async (patientId, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.get(`${url}/api/patient/files/${patientId}`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updateDocument = async (id, data, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.put(`${url}/api/patient/file/${id}`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const deleteDocument = async (id, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.delete(`${url}/api/patient/file/${id}`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getPatientCampaigns = async (patientId, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.get(`${url}/api/campaign/bypatient/${patientId}`, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updateCampaign = async (id, data, token) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    };
    const response = await axios.put(`${url}/api/campaign/${id}`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}