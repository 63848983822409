import { create } from 'zustand';
import { getPatients, createPatient, updatePatient, uploadFile, getAllFiles, getKeysByPatient, getPresignedUrl, getFileRecords, updateDocument, getPatientCampaigns, updateCampaign, deleteDocument } from '../api/patient.api';
import { useUserStore } from './user.store';
import { useTokenStore } from './token.store';

export const usePatientStore = create((set, get) => ({
  loading: false,
  patients: [],
  error: null,
  selectedPatient: null,
  selectedPatientFiles: [],
  patientSize: 0,
  openEditModal: false,
  myPatient: null,
  campaigns: [],
  setOpenEditModal: (open) => set({ openEditModal: open }),
  setPatients: (patients) => set({ patients }),
  findPatientByName: (name) => {
    const patients = get().patients;
    return patients.find(patient => `${decodeURIComponent(patient.name)}-${decodeURIComponent(patient.lastname)}` === name);
  },
  setSelectedPatient: (patient) => set({ selectedPatient: patient }),
  fetchPatients: async () => {
    try {
      const user = useUserStore.getState().user;
      const token = useTokenStore.getState().token;
      const selectedPatient = get().selectedPatient;
      const onGetFileRecords = get().onGetFileRecords;
      set({ loading: true });
      const patients = await getPatients(token);
      if (!patients.length) throw new Error('No patients found');
      if (!selectedPatient) {
        const myPatient = patients.find(patient => patient?.name === user?.name && patient?.lastname === user?.lastname);
        set({ selectedPatient: myPatient, myPatient });
        if (myPatient) {
          await onGetFileRecords(myPatient._id);
        }
      }
      set({ patients, loading: false });
    } catch (error) {
      set({ loading: false });
    }
  },
  onCreatePatient: async (patientData, onSuccess) => {
    try {
      const patients = get().patients;
      const token = useTokenStore.getState().token;
      set({ loading: true });
      const patient = await createPatient(patientData, token);
      set({ patients: [patient, ...patients ], loading: false });
      onSuccess();
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onUpdatePatient: async (id, patientData, onSuccess) => {
    try {
      const patients = get().patients;
      const token = useTokenStore.getState().token;
      set({ loading: true });
      const updatedPatient = await updatePatient(id, patientData, token);
      const updatedPatients = patients.map(patient => patient._id === id ? updatedPatient : patient);
      set({ patients: updatedPatients, loading: false });
      onSuccess(updatedPatient);
      return updatedPatient;
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onUploadFile: async (data, onSuccess) => {
    const onGetFileRecords = get().onGetFileRecords;
    const token = useTokenStore.getState().token;
    try {
      set({ loading: true });
      await uploadFile(data, token);
      await onGetFileRecords(data.get('patient'));

      set({ loading: false });
      onSuccess();
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onGetAllFiles: async () => {
    try {
      const token = useTokenStore.getState().token;
      set({ loading: true });
      const files = await getAllFiles(token);
      set({ loading: false });
      if (!files.length) throw new Error('No files found');
      const size = files.reduce((acc, file) => acc + file.Size, 0);
      set({ patientSize: size });
      // TODO - set files size to check the storage limit
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onGetFileRecords: async (patientId) => {
    try {
      const token = useTokenStore.getState().token;
      const files = await getFileRecords(patientId, token);
      set({ selectedPatientFiles: files });
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onGetPatientFiles: async (patientId) => {
    try {
      const token = useTokenStore.getState().token;
      set({ loading: true });
      const documents = await getKeysByPatient(patientId, token);
      if (!documents.length) throw new Error('No files found');
      let files = [];
      for (const doc of documents) {
        const url = await getPresignedUrl(doc.file, token);
        doc.url = url;
        files.push(doc);
      }
      set({ selectedPatientFiles: files, loading: false });
    } catch (error) {
      set({ loading: false, error: error.message })
    }
  },
  onGetPresignedUrlsForPatient: async (patientId) => {

  },
  onGetPresignedUrl: async (key) => {
    try {
      const token = useTokenStore.getState().token;
      const url = await getPresignedUrl(key, token);
      return url;
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onDocumentUpdate: async (id, data) => {
    try {
      const token = useTokenStore.getState().token;
      set({ loading: true });
      const updatedDocument = await updateDocument(id, data, token);
      const documents = get().selectedPatientFiles;
      const updatedDocuments = documents.map(doc => doc._id === id ? updatedDocument : doc);
      set({ loading: false, selectedPatientFiles: updatedDocuments });
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onGetPatientCampaigns: async (patientId) => {
    try {
      const token = useTokenStore.getState().token;
      set({ loading: true });
      const campaigns = await getPatientCampaigns(patientId, token);
      set({ loading: false });
      if (Array.isArray(campaigns)) {
        set({ campaigns });
      } else {
        set({ campaigns: [] });
      }
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onUpdateCampaign: async (campaignId, status) => {
    try {
      const token = useTokenStore.getState().token;
      set({ loading: true });
      const updated = await updateCampaign(campaignId, { status }, token);
      const campaigns = get().campaigns;
      const updatedCampaigns = campaigns.map(campaign => campaign._id === campaignId ? updated : campaign);
      set({ loading: false, campaigns: updatedCampaigns });
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onDeleteFile: async (id) => {
    try {
      const token = useTokenStore.getState().token;
      set({ loading: true });
      await deleteDocument(id, token);
      const documents = get().selectedPatientFiles;
      const updatedDocuments = documents.filter(doc => doc._id !== id);
      set({ loading: false, selectedPatientFiles: updatedDocuments });
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  }
}));
