export const reduceImageSize = (currentFile) => {
  return new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(currentFile);
  reader.onload = function () {
    const img = new Image();
    img.src = reader.result;
    img.onload = function () {
    const elem = document.createElement('canvas');
    const MAX_WIDTH = 400;
    const scaleSize = MAX_WIDTH / img.width;
    elem.width = MAX_WIDTH;
    elem.height = img.height * scaleSize;
    const ctx = elem.getContext('2d');
    ctx?.drawImage(img, 0, 0, elem.width, elem.height);
    ctx?.canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], `${new Date().getTime()}` , { type: currentFile.type, lastModified: Date.now() });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(file);
      };
      } else {
      reject(new Error('Image compression failed.'));
      }
    }, currentFile.type, 0.7);
    };
  };

  reader.onerror = function (error) {
    reject(error);
  };
  });
};